import { render, staticRenderFns } from "./subjectWisePassFail.vue?vue&type=template&id=7115c6a4&scoped=true&"
import script from "./subjectWisePassFail.vue?vue&type=script&lang=js&"
export * from "./subjectWisePassFail.vue?vue&type=script&lang=js&"
import style0 from "./subjectWisePassFail.vue?vue&type=style&index=0&id=7115c6a4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7115c6a4",
  null
  
)

export default component.exports